<template lang="pug">
.profile
  .text-h5.font-weight-bold.primary--text.mb-6 Bruker
  v-card.card-simple.rounded-xl.mb-8
    v-card-text.pa-8
      v-text-field.mb-2(
        v-model="email"
        label="Epost"
        type="email"
        append-icon="mdi-email"
        rounded filled disabled
      )
      v-text-field.mb-2(
        v-model="nickname"
        :disabled="loading_profile"
        label="Navn"
        append-icon="mdi-account"
        rounded filled autofocus
      )
      v-btn(
        :disabled="nickname === '' || loading_profile"
        :loading="loading_profile"
        @click="doUpdateProfile"
        color="secondary"
        x-large block depressed rounded
      ) Lagre

  .text-h5.font-weight-bold.primary--text.mb-6 Bytt passord
  v-card.card-simple.rounded-xl
    v-card-text.pa-8
      v-text-field.mb-2(
        v-model="password_old"
        :disabled="loading_password"
        placeholder="Nåværende passord"
        append-icon="mdi-form-textbox-password"
        type="password"
        rounded filled
      )
      v-text-field.mb-2(
        v-model="password_new"
        :disabled="loading_password"
        placeholder="Nytt passord"
        append-icon="mdi-form-textbox-password"
        type="password"
        rounded filled
      )
      v-text-field.mb-2(
        v-model="password_verify"
        :disabled="loading_password"
        placeholder="Bekreft nytt passord"
        append-icon="mdi-form-textbox-password"
        type="password"
        rounded filled
      )
      v-btn(
        :disabled="!change_password_ok || loading_password"
        :loading="loading_password"
        @click="doChangePassword"
        color="secondary"
        x-large block depressed rounded
      ) Lagre
</template>

<script>
import { get } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Profile',
  data: () => ({
    loading_profile: false,
    loading_password: false,
    nickname: '',
    email: '',
    password_old: '',
    password_new: '',
    password_verify: ''
  }),
  computed: {
    ...mapState('Cognito', ['user']),
    change_password_ok() {
      return (
        this.password_old !== '' &&
        this.password_new !== '' &&
        this.password_new === this.password_verify
      )
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Cognito', ['updateUser', 'changePassword']),
    async doUpdateProfile() {
      this.loading_profile = true
      try {
        await this.updateUser({ nickname: this.nickname })
        this.setSnackbar({
          type: 'success',
          msg: 'Profilen din er nå oppdatert'
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_profile = false
      }
    },
    async doChangePassword() {
      this.loading_password = true
      try {
        await this.changePassword({
          oldPassword: this.password_old,
          newPassword: this.password_new
        })
        this.setSnackbar({
          type: 'success',
          msg: 'Passordet er nå byttet'
        })
        this.password_old = ''
        this.password_new = ''
        this.password_verify = ''
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_password = false
      }
    }
  },
  mounted() {
    this.nickname = get(this.user, 'attributes.nickname', '')
    this.email = get(this.user, 'attributes.email', '')
  }
}
</script>

<style lang="stylus" scoped></style>
